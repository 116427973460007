import Navbar from "../components/Navbar";
import BlogPosts from "../components/BlogPosts";

const AllBlogPosts = () => {
    
    return (
        <>
            <Navbar/>
            <BlogPosts/>
        </>
    )
}

export default AllBlogPosts;