import AdminNavbar from "../components/AdminNavbar";
import Welcome from "../components/Welcome";

const HomeAdmin = () => {
    return (
        <>
            <AdminNavbar/>
            <Welcome/>
        </>
    )
}

export default HomeAdmin;